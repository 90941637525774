import _ from 'lodash'
import cookie from 'js-cookie'
import { Register } from './Register'
import {
  loadScript,
  getConfiguration,
  getMe,
  goTerms,
  getIsLogin,
  getLangMessage,
} from './helper'

import generate from '../plugins/utils/computed-color'

class Entry {
  constructor({
    global,
    layout,
    app,
    vue,
    router,
    store,
    i18n,
    progress,
  } = {}) {
    this.global = global
    this.layout = layout
    this.app = app
    this.vue = vue
    this.router = router
    this.store = store
    this.i18n = i18n
    this.progress = progress

    this.isDevelopment = process.env.NODE_ENV === 'development'
    this.vm = null
    this.microAppConfig = null
    this.loadedScripts = {}
    this.routes = []
    this.stores = []
    this.i18ns = []
    this.i18nLocal = {}
    this.register = new Register({ router, store, i18n, layout })

    this.init()
  }

  init() {
    if (!this.progress) {
      this.progress = {
        start: () => {
          // start
        },
        done: () => {
          // done
        },
      }
    }

    if (!this.layout) {
      throw new Error('Params.layout must be a vue component.')
    }

    if (!this.app) {
      throw new Error('Params.app must be a vue component.')
    }

    if (!this.vue) {
      throw new Error('Params.vue must be Vue.')
    }

    if (!this.router) {
      throw new Error('Params.router must be vue-router instance.')
    }

    if (!this.store) {
      throw new Error('Params.store must be vuex store instance.')
    }

    if (!this.i18n) {
      throw new Error('Params.i18n must be vue-i18n instance.')
    }
  }

  create() {
    this._injectMeta()
    this._setCookie()

    return this._loadAppConfig().then(async () => {
      this._inject()
      await this._getLangMessageFromCDN()
      this._routerGuard()
      this._setBrand()
      this._createApp()

      return this.vm
    })
  }

  /**
   * Register routes for local development.
   *
   * @param {String} name Portal name, if the portal is an entry portal, name = ''.
   * @param {String} prefix Route prefix, if the portal is an entry portal, prefix = ''.
   * @param {Array} routes
   */
  registerRoutes(name, prefix, routes) {
    this.routes.push([name.trim(), prefix.trim(), routes])
    return this
  }

  /**
   * @param {string} name
   * @param {Object} store
   */
  registerStore(name, store) {
    this.stores.push([name.trim(), store])
    return this
  }

  /**
   * @param {string} name
   * @param {Object} i18n
   */
  registerI18n(name, i18n) {
    if (process.env.VUE_APP_LOCAL_I18N === 'local') {
      this.i18nLocal = i18n
    }
    return this
  }

  log(message) {
    if (this.isDevelopment) {
      console.log(message)
    }

    return this
  }

  /**
   * Load app config from server or page.
   *
   * @param {Object} global window
   * @returns {Promise}
   */
  _loadAppConfig() {
    const { global, store } = this

    let promise
    if (this._isGreenLight()) {
      promise = getConfiguration().then((config) => {
        return {
          websiteCode: '',
          cloudFrontUrl: config?.settings?.cdnUrl,
          sessionDuration: config?.settings?.sessionDuration,
          cdnSubFolder: config?.settings?.cdnSubFolder,
          ...config,
        }
      })
    } else {
      // Note: we must call getIsLogin(), this method will remove HttpOnly for XSRF-TOKEN cookie.
      promise = Promise.all([getConfiguration(), getIsLogin()])
        .then(([config]) => {
          return {
            $jwt: config?.jwt,
            websiteCode: '',
            cloudFrontUrl: config?.settings?.cdnUrl,
            sessionDuration: config?.settings?.sessionDuration,
            cdnSubFolder: config?.settings?.cdnSubFolder,
            ...config,
          }
        })
        .then((config) => {
          return getMe().then((me) => {
            me = this._resetDefaultPage(me)
            store.commit('setUserInfo', me)
            return config
          })
        })
    }

    return promise.then((config) => {
      // Handling static resource paths
      function handleStaticResource(resource) {
        Object.keys(resource).forEach((key) => {
          resource[
            key
          ] = `${config.cloudFrontUrl}/static-resources/${resource[key]}`
        })
      }
      function handleCustomizedFileResource(resource = []) {
        resource.forEach(
          (item) =>
            (item.upload = `${config.cloudFrontUrl}/static-resources/${item.upload}`)
        )
      }
      if (config.settings) {
        handleStaticResource(config.settings.images || {})
        handleStaticResource(config.settings.staticFile || {})
        handleCustomizedFileResource(config.settings.customizedFile || [])
      }

      store.commit('setFrontendConfig', config)

      // For compatibility, need to remove. Get config from store.
      // eslint-disable-next-line no-unused-expressions
      global?.sessionStorage?.setItem('appConfig', JSON.stringify(config))

      // http headers for axios.
      const headers = {
        'X-REQUEST-WITH': config.csrf || '',
        'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN') || '',
      }
      if (config.$jwt) {
        headers['X-SN-JWT-TOKEN'] = config.$jwt
      }
      store.commit('setHttpHeaders', headers)

      return config
    })
  }

  _resetDefaultPage(me) {
    me.role = me.profile.roleCode
    // If defaultPagePath is not in the configured permission menu, modify it to be the first one of the permission menu of type topMenu
    let menuList = me?.roleConfiguration?.menuList || []
    let defaultPath = me?.roleConfiguration?.defaultPagePath || ''
    menuList = menuList.filter(
      (item) =>
        item.menuCode !== 'ADMIN_INSTITUTION' &&
        item.menuCode !== 'ADMIN_LOC_PRODUCT'
    )

    // reset client default page path to service client home
    const clientPortalDefaultPagePath = '/smp'
    const ServiceMyLinePath = '/service/my-line'
    const ServiceClientHome = '/service/client-home'
    const needResetToClientHome = [
      clientPortalDefaultPagePath,
      ServiceMyLinePath,
    ].includes(defaultPath)
    defaultPath = needResetToClientHome ? ServiceClientHome : defaultPath
    me.roleConfiguration.defaultPagePath = defaultPath

    let topMenu = menuList.filter((item) => {
      return item.type === 'topMenu' && item.path
    })
    topMenu = _.orderBy(topMenu, ['displayOrder'], ['asc'])

    const roleMenuNotIncludeDefaultPath = menuList.every((item) => {
      return item.path !== defaultPath
    })

    if (
      roleMenuNotIncludeDefaultPath &&
      me.roleConfiguration &&
      !_.isEmpty(topMenu) &&
      !needResetToClientHome
    ) {
      me.roleConfiguration.defaultPagePath = this._getTopMenuLink(
        topMenu[0],
        menuList
      )
    }

    if (me.roleConfiguration) {
      me.roleConfiguration.menuList = menuList
    }
    return me
  }

  _getTopMenuLink(topMenu, menuList, isChild) {
    const { path } = topMenu
    if (_.isEmpty(path) || path === 'IS_FIRST_CHILD_PATH') {
      let children = menuList.filter(
        (item) => item.parentMenuId === topMenu.menuId && item.path
      )
      if (!_.isEmpty(children)) {
        children = _.orderBy(children, ['displayOrder'], ['asc'])
        return this._getTopMenuLink(children[0], menuList, true)
      }
    }
    return path || '/'
  }

  _inject() {
    this.routes.forEach((item) => this.register.addRoutes(...item))
    this.stores.forEach((item) => this.register.addStore(...item))
    // this.i18ns.forEach((item) => this.register.addI18n(...item))
  }

  _getLangMessageFromCDN() {
    const { store } = this
    return getLangMessage(
      `${store.state.frontendConfig.settings.cdnUrl}${store.state.frontendConfig.cdnPath}`
    ).then((data) => {
      this.register.addI18nFormCDN(data || {})
      this.register.addLocaleI18n(this.i18nLocal || {})
    })
  }

  _injectMeta() {
    const { global, router, store, i18n, vue, register, layout } = this

    // This function for compatible.
    function addRouter(routes, name) {
      if (routes.length <= 0) {
        return
      }

      const route = [
        {
          path: `/entry-${name}`,
          component: layout,
          children: routes,
        },
      ]

      router.addRoutes(route)
    }

    global.microAppData = {
      vue,
      router,
      store,
      i18n,
      addRouter,
      register,
    }

    return this
  }

  /**
   * Only for development.
   */
  _setCookie() {
    if (!this.isDevelopment) {
      return
    }

    const token = 'XSRF-TOKEN'
    const session = 'SN-SESSION'
    const cookies = [token, session]
    const url = new URL(window.location)
    const queryToken = url.searchParams.get(token)
    const querySession = url.searchParams.get(session)

    if (querySession && queryToken) {
      Object.entries(cookie.get()).forEach(([name, _value]) => {
        cookie.remove(name)
      })

      cookies.forEach((name) => {
        const value = url.searchParams.get(name)
        if (value) {
          cookie.set(name, value)
        }
      })
    }
  }

  _routerGuard() {
    this._scriptLoaderGuard()
    this._authGuard()

    return this
  }

  _scriptLoaderGuard() {
    const { router } = this

    let currentRoute = null

    router.beforeEach((to, _from, next) => {
      currentRoute = to

      // Fixed, some route don't have name.
      const name = (to.name || '').toUpperCase()
      if (name === 'DEFAULT') {
        this._loadAppScript(to).then((route) => {
          if (currentRoute === route) {
            // when script was loaded, need rematch.
            next(route.fullPath)
          } else {
            next()
          }
        })
      } else {
        next()
      }
    })

    return this
  }

  _authGuard() {
    const { router, progress, store } = this
    const auth = (role, type, roles) => {
      const checked = roles.some((item) => {
        return item === role
      })

      if (type === 'white') {
        return checked
      }

      if (type === 'black') {
        return !checked
      }
    }

    router.beforeEach((to, _from, next) => {
      progress.start()

      const role = store.getters.role
      const permission = store.state.frontendConfig?.permission?.page?.[to.name]
      const passed = permission
        ? auth(role, permission.listType, permission.listData)
        : true

      if (passed) {
        next()
      } else {
        next('/not-permission')
      }
    })

    router.afterEach((to) => {
      store.commit('setCodePermissions', store.getters.codePermissions(to))
      progress.done()
    })

    return this
  }

  _createApp() {
    const { router, store, i18n, app, vue: Vue } = this

    this.global.vm = this.vm = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(app),
      el: '#app',
      created() {
        goTerms(router, store)
      },
    })

    return this
  }

  _setBrand() {
    const { frontendConfig = {} } = this.store.state

    // add theme css
    const themeCsses = [
      frontendConfig.settings?.customizeTheme || '',
      frontendConfig.settings?.theme || '',
    ]
    themeCsses.forEach((item) => {
      if (item) {
        const styleElement = document.createElement('style')
        styleElement.setAttribute('type', 'text/css')
        document.documentElement.appendChild(styleElement)
        if (styleElement.styleSheet) {
          styleElement.styleSheet.cssText = item
        } else {
          styleElement.appendChild(document.createTextNode(item))
        }
      }
    })

    // set favicon
    const favicon = frontendConfig.settings?.images?.favicon
    if (favicon) {
      const faviconElement = document.querySelector("link[rel*='icon']")
      faviconElement.href = favicon
    }

    // set title
    // const title = frontendConfig.settings?.title || 'Demo'
    // if (title) {
    //   const titleElement = document.querySelector("title[id='product-title']")
    //   titleElement.text = title
    // }

    // set mainColor
    const mainColor = frontendConfig.settings?.mainPageColor || ''
    if (mainColor) {
      this._setPrimaryColor(mainColor)
      const $root = document?.querySelector(':root')
      if ($root) {
        $root.style.setProperty('--primary', `var(--color-brand-06)`)
        // Use computed brand colors instead of manual HSL calculations
        $root.style.setProperty('--primary-lighten-10', `var(--color-brand-05)`)
        $root.style.setProperty('--primary-lighten-40', `var(--color-brand-02)`)
        $root.style.setProperty('--primary-darken-10', `var(--color-brand-08)`)
      }
    }
  }

  _setPrimaryColor(color) {
    const colors = generate(color)
    colors.forEach((colorItem, index) => {
      const $root = document?.querySelector(':root')
      if ($root) {
        $root.style.setProperty(
          `--color-brand-${(index + 1).toString().padStart(2, '0')}`,
          colorItem
        )
      }
    })
  }

  _isGreenLight() {
    const { store } = this
    const whitelistUrl = store.getters.whitelistUrl
    const path = global.location?.pathname
    const goToAppServerText =
      '[[auth or app]]: white list url [not] include this router path, user will go to app server'
    const goToAuthServerText =
      '[[auth or app]]: white list url include this router path, user will go to auth server'

    if (!path) {
      throw new Error('Invalid path.')
    }

    if (path.indexOf('/auth') > -1) {
      this.log(goToAuthServerText)
      return true
    }

    if (whitelistUrl.includes(path)) {
      this.log(goToAuthServerText)
      return true
    }

    this.log(goToAppServerText)
    return false
  }

  _sanitizeMicroAppConfig() {
    if (this.microAppConfig === null) {
      const { store } = this
      const { frontendConfig = {} } = store.state
      const { cloudFrontUrl } = frontendConfig
      const microApp = _.cloneDeep(frontendConfig?.settings?.microApp || {})
      Object.keys(microApp).forEach((key) => {
        const productName = microApp[key].productName.trim()
        const basePath = microApp[key].basePath.trim()
        const defaultVersion = microApp[key].version.trim()
        const greenVersion = microApp[key].greenVersion?.trim()
        const version =
          cookie.get('x-sn-ver')?.toLowerCase() === 'green'
            ? greenVersion || defaultVersion
            : defaultVersion
        microApp[key].productName = productName
        microApp[key].basePath = '/' + _.trim(basePath, '/')
        microApp[
          key
        ].cdn = `${cloudFrontUrl}/${productName}/${version}/${productName}.umd.min.js`
      })
      this.microAppConfig = microApp
    }
    return this.microAppConfig
  }

  async _loadAppScript(to) {
    const { progress, routes } = this

    // Micro App config empty.
    const microAppConfig = this._sanitizeMicroAppConfig()
    if (Object.keys(microAppConfig).length <= 0) {
      return
    }

    // Route prefix is not in config
    const pos = to.path.indexOf('/', 1)
    const routePrefix = pos === -1 ? to.path : to.path.substring(0, pos)
    const config = microAppConfig[routePrefix]
    if (!config) {
      return
    }

    // Route is registered from local.
    const isRegistered = routes.some((item) => item.name === config.productName)
    if (isRegistered) {
      return
    }

    // If script loaded.
    const { productName, cdn } = config
    if (this.loadedScripts[productName]?.[1] === 1) {
      return
    }

    const loader = this.loadedScripts[productName] ?? [null, 0]
    if (loader[0] === null) {
      progress.start()
      loader[0] = loadScript(cdn).then(() => {
        loader[1] = 1
        return to
      })
    } else {
      loader[0] = loader[0].then(() => {
        return to
      })
    }

    this.loadedScripts[productName] = loader
    return loader[0]
  }
}

export { Entry }
