import { inputToRGB, rgbToHex, rgbToHsv } from '@ctrl/tinycolor'

const hueStep = 2 // Hue ladder

const saturationStep = 0.16 // Saturation gradient, light color section.
const saturationStep2 = 0.05 // Saturation gradient, dark portion.
const brightnessStep1 = 0.05 // Brightness levels, light areas.
const brightnessStep2 = 0.15 // Brightness levels, dark areas.
const lightColorCount = 5 // Light color quantity, on the main color.
const darkColorCount = 4 // Quantity of dark colors, subordinated to the main color.
// Dark theme color mapping table
const darkColorMap = [
  { index: 7, opacity: 0.15 },
  { index: 6, opacity: 0.25 },
  { index: 5, opacity: 0.3 },
  { index: 5, opacity: 0.45 },
  { index: 5, opacity: 0.65 },
  { index: 5, opacity: 0.85 },
  { index: 4, opacity: 0.9 },
  { index: 3, opacity: 0.95 },
  { index: 2, opacity: 0.97 },
  { index: 1, opacity: 0.98 },
]

// Wrapper function ported from TinyColor.prototype.toHsv
function toHsv({ r, g, b }) {
  const hsv = rgbToHsv(r, g, b)
  return { h: hsv.h * 360, s: hsv.s, v: hsv.v }
}

// Wrapper function ported from TinyColor.prototype.toHexString
function toHex({ r, g, b }) {
  return `#${rgbToHex(r, g, b, false)}`
}

// Wrapper function ported from TinyColor.prototype.mix, not treeshakable.
// Amount in range [0, 1]
// Assume color1 & color2 has no alpha, since the following src code did so.
function mix(rgb1, rgb2, amount) {
  const p = amount / 100
  const rgb = {
    r: (rgb2.r - rgb1.r) * p + rgb1.r,
    g: (rgb2.g - rgb1.g) * p + rgb1.g,
    b: (rgb2.b - rgb1.b) * p + rgb1.b,
  }
  return rgb
}

function getHue(hsv, i, light) {
  let hue
  // The hue shifts differently depending on the different hues.
  if (Math.round(hsv.h) >= 60 && Math.round(hsv.h) <= 240) {
    hue = light
      ? Math.round(hsv.h) - hueStep * i
      : Math.round(hsv.h) + hueStep * i
  } else {
    hue = light
      ? Math.round(hsv.h) + hueStep * i
      : Math.round(hsv.h) - hueStep * i
  }
  if (hue < 0) {
    hue += 360
  } else if (hue >= 360) {
    hue -= 360
  }
  return hue
}

function getSaturation(hsv, i, light) {
  // grey color don't change saturation
  if (hsv.h === 0 && hsv.s === 0) {
    return hsv.s
  }
  let saturation
  if (light) {
    saturation = hsv.s - saturationStep * i
  } else if (i === darkColorCount) {
    saturation = hsv.s + saturationStep
  } else {
    saturation = hsv.s + saturationStep2 * i
  }
  // Boundary value adjustment
  if (saturation > 1) {
    saturation = 1
  }
  // The first value of s is restricted to be between 0.06 and 0.1.
  if (light && i === lightColorCount && saturation > 0.1) {
    saturation = 0.1
  }
  if (saturation < 0.06) {
    saturation = 0.06
  }
  return Number(saturation.toFixed(2))
}

function getValue(hsv, i, light) {
  let value
  if (light) {
    value = hsv.v + brightnessStep1 * i
  } else {
    value = hsv.v - brightnessStep2 * i
  }
  if (value > 1) {
    value = 1
  }
  return Number(value.toFixed(2))
}

export default function generate(color, opts = {}) {
  const patterns = []
  const pColor = inputToRGB(color)
  for (let i = lightColorCount; i > 0; i -= 1) {
    const hsv = toHsv(pColor)
    const colorString = toHex(
      inputToRGB({
        h: getHue(hsv, i, true),
        s: getSaturation(hsv, i, true),
        v: getValue(hsv, i, true),
      })
    )
    patterns.push(colorString)
  }
  patterns.push(toHex(pColor))
  for (let i = 1; i <= darkColorCount; i += 1) {
    const hsv = toHsv(pColor)
    const colorString = toHex(
      inputToRGB({
        h: getHue(hsv, i),
        s: getSaturation(hsv, i),
        v: getValue(hsv, i),
      })
    )
    patterns.push(colorString)
  }

  // dark theme patterns
  if (opts.theme === 'dark') {
    return darkColorMap.map(({ index, opacity }) => {
      const darkColorString = toHex(
        mix(
          inputToRGB(opts.backgroundColor || '#141414'),
          inputToRGB(patterns[index]),
          opacity * 100
        )
      )
      return darkColorString
    })
  }
  return patterns
}
