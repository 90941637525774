<script>
export default {
  render() {
    const icon = this.getRenderIcon()
    const menuItem = (item) => {
      return (
        <s-menu-item
          id={this.$generateId('menu', item.menuCode)}
          index={this.getFullPath(item)}
          key={item.label}
          page-name={item.label}
          style={`${item.visibility ? '' : 'display: none;'} ${
            this.sideWidth ? 'opacity:0;' : 'opacity:1;'
          }`}
          nativeOnClick={($event) => {
            this.goto($event, item)
          }}
          on-keydown={($event) => {
            this.goto($event, item)
          }}
          disabled={item.disabled}
        >
          {icon(item)}
          <span>{item.label}</span>
        </s-menu-item>
      )
    }

    const subMenu = (item) => {
      return (
        <s-sub-menu
          id={this.$generateId('submenu', item.menuCode)}
          index={this.getFullPath(item)}
          key={item.label}
          style={item.visibility ? '' : 'display: none;'}
          nativeOnClick={($event) => {
            this.goto($event, item, true)
          }}
          on-keydown={($event) => {
            this.goto($event, item, true)
          }}
          disabled={item.disabled}
          title-link={item.titleLink}
        >
          <template slot="title">
            {icon(item)}
            <span>{item.label}</span>
          </template>
          <s-menu-item-group>
            {subMenuOrMenuItem(item.children)}
          </s-menu-item-group>
        </s-sub-menu>
      )
    }

    const subMenuOrMenuItem = (data) => {
      return data.map((item) => {
        return item.children ? subMenu(item) : menuItem(item)
      })
    }
    const style = this.sideWidth ? 'width: 0' : ''
    return this.sidebarData.length > 0 ? (
      <div
        class="sidebar-wrap test-color flex-grow-0 flex-shrink-0"
        style={style}
      >
        <s-menu
          handSwitch={this.handSwitch}
          inlineCollapsed={this.$store.getters.isShowMobileMenu}
          class="sidebar-menu"
          onChangeCollapseStatus={this.changeCollapseStatus}
          style={`height: calc(100vh - ${this.headerNavbarHeight}px); top: ${this.headerNavbarHeight}px;`}
          selectedIndex={this.selectedIndex}
        >
          {subMenuOrMenuItem(this.sidebarData)}
        </s-menu>
      </div>
    ) : null
  },
  props: {
    headerNavbarHeight: {
      type: Number,
      default: 56,
    },
  },
  data() {
    return {
      selectedIndex: '',
      sideWidth: null,
    }
  },
  computed: {
    sidebarData() {
      return this.$store.state.sidebarData || []
    },
    handSwitch() {
      return (
        this.$store.getters.getMenuHandCollapseSwitch ||
        this.$store.getters.isShowMobileMenu
      )
    },
  },
  watch: {
    $route() {
      this.init()
      this.setHighlightMenuIndex()
    },
    sidebarData(val, oldVal) {
      if (oldVal?.length === 0) this.selectedIndex = ''
      if (val.length === 0 && oldVal?.length) {
        this.$nextTick(() => {
          this.sideWidth = false
        })
      }
      this.setHighlightMenuIndex()
    },
  },
  created() {
    this.init()
  },
  methods: {
    setHighlightMenuIndex() {
      const currentRoute =
        this.sidebarData
          .map((menu) => this.getRouterFullPath(menu))
          .find((fullPath) => fullPath) || ''
      this.selectedIndex = currentRoute
    },
    init() {
      const isMatchedRoute = this.sidebarData
        .map((menu) => this.getRouterFullPath(menu))
        .some((fullPath) =>
          this.getCleanPath(String(fullPath)).includes(this.$route?.path)
        )
      if (!isMatchedRoute) {
        this.$store.commit(
          'setSidebarData',
          this.$store.getters.sidebarData(this.$route)
        )
      }
    },
    getCleanPath(path) {
      return path.replace(/\?.*/, '')
    },
    getRenderPath(item) {
      return item.icon.path ? <path d={item.icon.path}></path> : null
    },
    getRenderIcon() {
      const vm = this
      return (item) => {
        if (item.icon) {
          const name = item.icon.path ? undefined : item.icon.name
          return (
            <s-icon
              icon-name={name}
              icon-color={item.icon.color || '#60759A'}
              width={item.icon.width || 24}
              height={item.icon.height || 24}
            >
              {vm.getRenderPath(item)}
            </s-icon>
          )
        }
        return null
      }
    },
    goto(e, item, isSubMenu = false) {
      e.stopPropagation && e.stopPropagation()
      if (isSubMenu && item?.titleLink !== true) {
        const firstChild = this.findFirstChild(item) || {}
        if (firstChild.path && firstChild.path !== 'IS_FIRST_CHILD_PATH') {
          this.$router.push({
            path: firstChild.path,
            query: {
              ...this.inheritQuery(firstChild),
              ...item.query,
            },
          })
          return
        }
      }
      if (
        (isSubMenu && item?.titleLink !== true) ||
        this.$utils.isEmpty(item.path) ||
        this.$route.fullPath === item.path ||
        (this.$route.path === item.path &&
          this.$lodash.isEqual(item?.query, this.$route?.query))
      ) {
        return
      }
      if (item.externalLink) {
        window.open(item.path)
        return
      }
      this.$router.push({
        path: item.path,
        query: {
          ...this.inheritQuery(item),
          ...item.query,
        },
      })
    },
    findFirstChild(item) {
      if (!item.children || !item.children.length) {
        return null
      }
      const includeCurrentRoute = item.children.some(
        (menu) => menu.path === this.$route.path
      )
      if (includeCurrentRoute) {
        return null
      }
      const firstChild = item.children[0]
      if (firstChild.children && firstChild.children.length) {
        const deepChild = this.findFirstChild(firstChild)
        if (deepChild) {
          return deepChild
        }
      }
      return firstChild
    },
    getRouterFullPath(route) {
      const { path, children, query } = route
      let indexPath = ''
      if (path && this.$route.fullPath.indexOf(path) > -1) {
        if (
          this.$lodash.isEmpty(query) ||
          this.$lodash.isEqual(query, this.$route.query) ||
          this.$lodash.isMatch(this.$route.query, query)
        ) {
          indexPath = this.getFullPath(route)
        }
      }
      if (this.$utils.isEmpty(indexPath) && !this.$utils.isEmpty(children)) {
        indexPath =
          children
            .map((child) => this.getRouterFullPath(child))
            .find((route) => route) || ''
      }
      return indexPath
    },
    formatQueryToPath(query) {
      return Object.entries(query)
        .reduce((acc, [key, value]) => {
          !!value && acc.push(`${key}=${value}`)
          return acc
        }, [])
        .join('&')
    },

    getFullPath(item) {
      if (!this.$utils.isEmpty(item.query)) {
        return `${item.path}?${this.formatQueryToPath(item.query)}`
      }
      return item.path
    },

    changeCollapseStatus(value) {
      if (!this.handSwitch) {
        return
      }
      this.$store.commit('changeSidebarStatus')
      this.sideWidth = value
    },
    inheritQuery(to) {
      const inheritQuery = {}
      const inheritQueryKeys = [
        'totalLoanFacilityId',
        'loanFacilityId',
        'collateralId',
      ]
      const toQuery = this.getQuery(to)
      const currentQuery = this.$route.query
      for (const key of Object.keys(currentQuery)) {
        if (inheritQueryKeys.includes(key)) {
          inheritQuery[key] = toQuery[key] || currentQuery[key]
        }
      }
      return inheritQuery
    },

    getQuery(route) {
      if (route?.path?.includes('?')) {
        const queryString = route.path.split('?')[1] || ''
        return queryString.split('&').reduce((acc, cur) => {
          const [key, value] = cur.split('=')
          acc[key] = value
          return acc
        }, {})
      }
      return route?.query || {}
    },
  },
}
</script>
<style lang="scss" scoped>
.sidebar-wrap {
  background-color: $white;
  width: 255px;
  :deep(.s-menu) {
    background: #fff;
    &.s-menu-item {
      transition: opacity 0.3s;
    }
    .s-menu-item-group {
      .s-menu-item {
        padding-left: 40px !important;
        a {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .s-sub-menu-wrap {
        .s-menu-item {
          padding-left: 60px !important;
        }
      }
    }
  }
  .sidebar-menu {
    position: fixed;
    z-index: $zindex-sticky;
  }
}
</style>
